<template>
  <div>
    <b-row class="match-height">
      <b-col md="12" lg="4">
        <b-card>
          <div class="d-flex align-items-center">
            <h3 class="mb-0 blueC">{{$t('campaigns.dateCampaign')}}</h3>
            <b-button
              variant="flat-primary"
              class="btn-icon"
              @click="showEdit('dates')"
              :disabled="disabled_update"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
          <b-row class="mt-1">
            <b-col md="12" lg="5">
              <h4 class="mb-0"><strong>{{ campaign.init_date }}</strong></h4>
              <p>{{$t('campaigns.startDate')}}</p>
            </b-col>
            <b-col md="12" lg="2">
              <h4 class="mb-0"><strong>-</strong></h4>
            </b-col>
            <b-col md="12" lg="5">
              <h4 class="mb-0"><strong>{{ campaign.end_date }}</strong></h4>
              <p>{{$t('campaigns.finishDate')}}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12" lg="4">
        <b-card>
          <div class="d-flex align-items-center">
            <h3 class="mb-0 blueC">{{$t('campaigns.Budget')}}</h3>
            <b-button
              variant="flat-primary"
              class="btn-icon"
              @click="showEdit('budget')"
              :disabled="disabled_update"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
          <h4 class="mt-1"><strong>$ {{ utils.separatebycomma(campaign.budget) }}</strong></h4>
        </b-card>
      </b-col>
      <b-col md="12" lg="4" v-if="role !== 'realty'">
        <b-card>
          <div class="d-flex align-items-center">
            <h3 class="mb-0 blueC">{{$t('campaigns.brand')}}</h3>
            <b-button
              variant="flat-primary"
              class="btn-icon"
              @click="showEdit('brand')"
              :disabled="disabled_update"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
          <h4 class="mt-1"><strong>{{ brand_reference_name }}</strong></h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="role !== 'realty'">
      <b-col>
        <b-card>
          <div class="d-flex align-items-center">
            <h3 class="mb-0 blueC">{{$t('campaigns.messageInvitation')}}</h3>
            <b-button
              variant="flat-primary"
              class="btn-icon"
              @click="showEdit('invitation')"
              :disabled="disabled_update"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
          <div class="plusGrand" v-html="campaign.custom_message"></div>
        </b-card>
      </b-col>
    </b-row>

    <modal-brandme :show_modal="show_modal_dates" :key="`modal_dates_${change_modal_dates}`">
      <template #title>{{$t('campaigns.validityCampaign')}}</template>
      <template #body>
        <div class="w-100 d-flex">
          <div class="mr-2 w-p-48">
            <label>{{$t('campaigns.startDate')}}</label>
            <b-form-datepicker
              id="fecha-inicio"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="init_date"
              class="mb-1"
            />
          </div>
          <div class="w-p-48">
            <label>{{$t('campaigns.finishDate')}}</label>
            <b-form-datepicker
              id="fecha-final"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="end_date"
              class="mb-1"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <b-button variant="outline-secondary" class="mr-1" @click="closeEdit('dates')">{{$t('campaigns.close')}}</b-button>
          <b-button variant="primary" @click="updateDates()">{{$t('dashboard.save')}}</b-button>
        </div>
      </template>
    </modal-brandme>

    <modal-brandme :show_modal="show_modal_budget" :key="`modal_budget_${change_modal_budget}`">
      <template #title>{{$t('campaigns.budgetChange')}}</template>
      <template #body>
        <b-input-group prepend="$">
          <cleave
            v-model="budget"
            :options="options_number"
            class="form-control"
            :raw="false"
            :maxlength="10"
            @input="validateBudget"
          />
        </b-input-group>
        <div class="d-flex justify-content-end mt-1">
          <b-button variant="outline-secondary" class="mr-1" @click="closeEdit('budget')">{{$t('campaigns.close')}}</b-button>
          <b-button variant="primary" @click="updateBudget()">{{$t('dashboard.save')}}</b-button>
        </div>
      </template>
    </modal-brandme>

    <modal-brandme :show_modal="show_modal_brand" :key="`modal_brand_${change_modal_brand}`">
      <template #title>{{$t('campaigns.validityCampaign')}}</template>
      <template #body>
        <b-form-group>
          <v-select
            v-model="brand"
            label="title"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="brands"
          />
        </b-form-group>
        <div class="d-flex justify-content-end mt-1">
          <b-button variant="outline-secondary" class="mr-1" @click="closeEdit('brand')">{{$t('campaigns.close')}}</b-button>
          <b-button variant="primary" @click="updateBrand()">{{$t('dashboard.save')}}</b-button>
        </div>
      </template>
    </modal-brandme>

    <modal-brandme size="xl" :show_modal="show_modal_invitation" :key="`modal_invitation_${change_modal_invitation}`">
      <template #title>{{$t('campaigns.changeLinkInvitation')}}</template>
      <template #body>
        <b-form-checkbox
          v-model="show_in_link"
          class="custom-control-primary text-left mt-1 mb-1"
        >
          {{$t('campaigns.showMessage')}}
        </b-form-checkbox>
        <b-form-group>
          <component
            :is="quill_editor"
            id="quil-content"
            v-model="custom_message"
            :options="editorOption"
            class="border-bottom-0 mw-100 h-150"
          />
          <div id="message-quill-toolbar" class="d-flex justify-content-end border-top-0">
            <!-- Add a bold button -->
            <button class="ql-bold" />
            <button class="ql-italic" />
            <button class="ql-underline" />
            <button class="ql-align" />
            <button class="ql-link" />
          </div>
        </b-form-group>
        <div class="d-flex justify-content-end mt-1">
          <b-button variant="outline-secondary" class="mr-1" @click="closeEdit('invitation')">{{$t('campaigns.close')}}</b-button>
          <b-button variant="primary" @click="updateMessagesInvitation()">{{$t('dashboard.save')}}</b-button>
        </div>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormDatepicker,
  BInputGroup,
  BFormGroup,
  BFormCheckbox
} from "bootstrap-vue";
import ModalBrandme from "@/views/components/modal/ModalBrandme.vue";
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import utils from '@/libs/utils'

export default {
  name: 'infoCampaign',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormDatepicker,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    ModalBrandme,
    Cleave,
    vSelect,
  },
  props: {
    campaign: {
      type: Object,
      default: () => {}
    },
    role: {
      type: String,
      default: ''
    },
    brands: {
      type: Array,
      default: () => []
    },
    disabled_update: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      utils,
      brand_reference_name: this.campaign && this.campaign.brand_reference && this.campaign.brand_reference.name ? this.campaign.brand_reference.name : 'www',
      init_date: this.campaign ? this.campaign.init_date : '',
      end_date: this.campaign ? this.campaign.end_date : '',
      budget: this.campaign ? this.campaign.budget : 0,
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      brand: {title: this.$t('campaigns.selectBrand'), uuid: ''},
      custom_message: this.campaign.custom_message,
      show_in_link: this.campaign ? this.campaign.show_custom_message : true,
      show_modal_dates: false,
      change_modal_dates: 0,
      show_modal_budget: false,
      change_modal_budget: 0,
      show_modal_brand: false,
      change_modal_brand: 0,
      show_modal_invitation: false,
      change_modal_invitation: 0,
      quill_editor: null,
    }
  },
  setup (props, context) {
    const editorOption = {
      modules: {
        toolbar: `#message-quill-toolbar`
      },
      placeholder: context.parent.$t('campaigns.writeHere')
    }
    return {
      editorOption
    }
  },
  created() {
    if (this.campaign && this.campaign.brand_reference) {
      this.brand = {
        title: this.campaign.brand_reference.name,
        uuid: this.campaign.brand_reference.uuid,
      }
    }
  },
  async mounted() {
    const vue_quill_editor = (await import('vue-quill-editor'));
    this.quill_editor = vue_quill_editor.quillEditor;
  },
  methods: {
    validateBudget() {
      const maxBudget = 10000000;
      const enteredBudget = parseInt(this.budget.replace(/,/g, ''), 10);
      if (enteredBudget > maxBudget) {
        this.budget = '10,000,000';
      }
    },
    showEdit(modal) {
      this[`show_modal_${modal}`] = true
      this[`change_modal_${modal}`] += 1
    },
    closeEdit(modal) {
      this[`show_modal_${modal}`] = false
      this[`change_modal_${modal}`] += 1
    },
    updateDates() {
      if (this.disabledUpdateCampaign()) return;

      const formData = new FormData()
      formData.append('init_date', this.init_date)
      formData.append('end_date', this.end_date)

      this.$emit('update_campaign', formData)
      this.closeEdit('dates')
    },
    updateBudget() {
      if (this.disabledUpdateCampaign()) return;
      const formData = new FormData()
      formData.append('budget', this.budget.replace(/,/g, ''))
      this.$emit('update_campaign', formData)
      this.closeEdit('budget')
    },
    updateBrand() {
      if (this.disabledUpdateCampaign()) return;
      const formData = new FormData()
      formData.append('brand_reference', this.brand.uuid)

      this.$emit('update_campaign', formData)
      this.closeEdit('brand')
    },
    updateMessagesInvitation() {
      if (this.disabledUpdateCampaign()) return;
      const formData = new FormData()
      formData.append('custom_message', this.custom_message)
      formData.append('show_custom_message', this.show_in_link)
      this.$emit('update_campaign', formData)
      this.closeEdit('invitation')
    },
    disabledUpdateCampaign() {
      return !utils.hasPermission('change_campaign')
    },
  },
  watch: {
    '$i18n.locale'() {
      this.editorOption.placeholder = this.$t('campaigns.writeHere')
    }
  }
};
</script>

<style>
</style>
